import { inject, Injectable } from '@angular/core';
import type {
  IFilterMethod,
  IHistoryParams,
  IInventoryItem,
  IInventoryRequestParams,
  IShopRequestParams,
  IWithdrawalRequest,
  IWithdrawalRequestParams,
  MarketSortingTypes,
  ModalNames,
} from '@dev-fast/types';
import { WalletTypeEnum } from '@dev-fast/types';
import type { TypedSelector } from '@ngxs/store';
import { Store } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import type { Observable } from 'rxjs';
import { combineLatest, map } from 'rxjs';

import { AuthState } from '@app/auth';
import { CurrencyService } from '@app/core/currency';
import type { InventoryStateModel } from '@app/core/state/inventory';
import {
  ApplyInventoryFilters,
  ChangeHistoryParams,
  ChangeInventoryPage,
  ChangeParamsInventory,
  ChangeParamsShop,
  ChangeShopPage,
  ClearPreSearchItemsLength,
  ClearTotalSum,
  ClickOnInventoryItem,
  ClickOnShopItem,
  FreezeItems,
  GetInventoryItems,
  GetShopItems,
  GetWithdrawalItems,
  InventoryState,
  Purchase,
  Refresh,
  RequestInventoryHistory,
  ResetPartialState,
  SellAllItems,
  SellItems,
  ToggleAllInventoryItems,
  ToggleIsSelectAll,
  Trade,
  UnselectItems,
  UpdateTotalSum,
  WithdrawItems,
} from '@app/core/state/inventory';
import { LayoutState, ToggleBackground } from '@app/core/state/layout';
import { CloseModal, ModalsState, OpenModal } from '@app/core/state/modals';
import { UserState } from '@app/core/state/user-store';
import { CommonStoreService } from '@app/core-state-common';

@Injectable({ providedIn: 'root' })
export class InventoryService {
  // Подключение сервисов
  readonly commonStoreService = inject(CommonStoreService);
  readonly currencyService = inject(CurrencyService);
  readonly #store = inject(Store);

  // Переменные сервисов
  // AuthState
  readonly isAuth$ = this.#store.select(AuthState.isAuth);

  // InventoryState
  readonly actionsDisabled$ = this.#store.select(InventoryState.actionsDisabled);
  readonly contracts$ = this.#store.select(InventoryState.contracts);
  readonly currentFiltersAllSold$ = this.#store.select(InventoryState.currentFiltersAllSold);
  readonly currentSum$ = this.#store.select(InventoryState.currentSum);
  readonly historyItems$ = this.#store.select(InventoryState.historyItems);
  readonly historyItemsLoading$ = this.#store.select(InventoryState.historyItemsLoading);
  readonly historyPagesNumber$ = this.#store.select(InventoryState.historyPagesNumber);
  readonly historyParams$ = this.#store.select(InventoryState.historyParams);

  // был <IInventoryRequestParams> без null
  readonly inventoryParams$ = this.#store.select(InventoryState.inventoryParams as TypedSelector<IInventoryRequestParams>);
  readonly inventoryShortInfo$ = this.#store.select(InventoryState.inventoryShortInfo);

  // был <boolean> без null
  readonly isLoading$ = this.#store.select(InventoryState.isItemsLoading as TypedSelector<boolean>);
  readonly isSelectAll$ = this.#store.select(InventoryState.isSelectAll);

  // был <IInventoryItem[]> без null
  readonly items$ = this.#store.select(InventoryState.items as TypedSelector<IInventoryItem[]>);
  readonly itemsCount$ = this.#store.select(InventoryState.itemsCount);
  readonly maxFilterPages$ = this.#store.select(InventoryState.maxFilterPages);
  readonly maxInventoryPages$ = this.#store.select(InventoryState.maxInventoryPages);
  readonly maxShopPages$ = this.#store.select(InventoryState.maxShopPages);
  readonly preliminarySum$ = this.#store.select(InventoryState.preliminarySum);
  readonly preSearchCount$ = this.#store.select(InventoryState.preSearchItemsLength);

  // был <IInventoryItem[]>
  readonly selectedItems$ = this.#store.select(InventoryState.selectedItems as TypedSelector<IInventoryItem[]>);

  // был <number | null> без undefined
  readonly selectionAmount$ = this.#store.select(InventoryState.selectionAmount as TypedSelector<number | null>);
  readonly shopItems$ = this.#store.select(InventoryState.shopItems);
  readonly shopMeta$ = this.#store.select(InventoryState.shopMeta);
  readonly shopParams$ = this.#store.select(InventoryState.shopParams);

  // был <Array<IFilterMethod<MarketSortingTypes>> | null>
  readonly sortingMethods$ = this.#store.select(
    InventoryState.sortingMethods as unknown as TypedSelector<Array<IFilterMethod<MarketSortingTypes>> | null>,
  );

  readonly totalSum$ = this.#store.select(InventoryState.totalSum);
  readonly withdrawalList$ = this.#store.select(InventoryState.withdrawalList);

  // LayoutState
  readonly breakpoints$ = this.#store.select(LayoutState.breakpoints);

  // ModalsState
  readonly activeModals$ = this.#store.select(ModalsState.activeModals); // был any

  // UserState
  readonly user$ = this.#store.select(UserState.user);

  // Other
  readonly currentSelectedItemsSum$ = this.selectedItems$.pipe(map((items) => items.reduce((acc, { price }) => acc + price, 0)));
  readonly selectedShopItemsPrice$ = this.contracts$.pipe(map((items) => items.reduce((acc, { price }) => acc + price, 0)));
  readonly userBalance$ = this.commonStoreService.getWalletById(WalletTypeEnum.BALANCE);
  readonly userBalanceDelta$: Observable<number> = combineLatest([
    this.currentSelectedItemsSum$,
    this.selectedShopItemsPrice$,
    this.isSelectAll$,
    this.inventoryShortInfo$,
  ]).pipe(
    map(([inventorySelectedSum, shopSelectedSum, isSelectAll, { inventorySum }]) => {
      return (isSelectAll ? inventorySum : inventorySelectedSum) - shopSelectedSum;
    }),
  );

  // Прочие методы
  convertValue = (number: number): number => {
    return this.currencyService.convert(number);
  };

  @Dispatch() getWithdrawalItems = (params: IWithdrawalRequestParams): GetWithdrawalItems => new GetWithdrawalItems(params);
  @Dispatch() withdrawItems = (withdrawalRequest: IWithdrawalRequest): WithdrawItems => new WithdrawItems(withdrawalRequest);
  @Dispatch() applyFilters = (): ApplyInventoryFilters => new ApplyInventoryFilters();
  @Dispatch() toggleBackground = (background?: string): ToggleBackground => new ToggleBackground(background);
  @Dispatch() getInventoryHistory = (): RequestInventoryHistory => new RequestInventoryHistory();
  @Dispatch() getInventoryItems = (params?: IInventoryRequestParams, buffer?: boolean, needUpdateTotalSum?: boolean): GetInventoryItems =>
    new GetInventoryItems(params, buffer, needUpdateTotalSum);

  @Dispatch() onItemClick = (id: number): ClickOnInventoryItem => new ClickOnInventoryItem(id);
  @Dispatch() onShopItemClick = (id: number): ClickOnShopItem => new ClickOnShopItem(id);
  @Dispatch() toggleAllInventoryItems = (): ToggleAllInventoryItems => new ToggleAllInventoryItems();
  @Dispatch() sellItems = (ids: number[]): SellItems => new SellItems(ids);
  @Dispatch() openModal = (modal: ModalNames, params?: any): OpenModal => new OpenModal(modal, params);
  @Dispatch() closeModal = (modal: ModalNames): CloseModal => new CloseModal(modal);
  @Dispatch() getShop = (design?: 'old' | 'new'): GetShopItems => new GetShopItems(design);
  @Dispatch() changeParams = (params: IInventoryRequestParams): ChangeParamsInventory => new ChangeParamsInventory(params);
  @Dispatch() changeShopParams = (params: IShopRequestParams): ChangeParamsShop => new ChangeParamsShop(params);

  @Dispatch() refresh = (payload: 'inventory' | 'trade'): Refresh => new Refresh(payload);
  @Dispatch() trade = (): Trade => new Trade();
  @Dispatch() changeShopPage = (page: number): ChangeShopPage => new ChangeShopPage(page);
  @Dispatch() changeInventoryPage = (page: number): ChangeInventoryPage => new ChangeInventoryPage(page);
  @Dispatch() unselectItems = (payload?: 'shop' | 'inventory'): UnselectItems => new UnselectItems(payload);
  @Dispatch() sellAll = (useTimer = false, useFilters = false): SellAllItems => new SellAllItems(useTimer, useFilters);
  @Dispatch() changeHistoryParams = (payload: IHistoryParams): ChangeHistoryParams => new ChangeHistoryParams(payload);
  @Dispatch() toggleIsSelectAll = (): ToggleIsSelectAll => new ToggleIsSelectAll();
  @Dispatch() freezeItems = (ids: number[]): FreezeItems => new FreezeItems(ids);
  @Dispatch() purchase = (ids: number[], userInventoryIds: number[]): Purchase => new Purchase(ids, userInventoryIds);
  @Dispatch() clearPreSearchItemsLength = (): ClearPreSearchItemsLength => new ClearPreSearchItemsLength();
  @Dispatch() clearTotalSum = (): ClearTotalSum => new ClearTotalSum();
  @Dispatch() updateTotalSum = (): UpdateTotalSum => new UpdateTotalSum();

  @Dispatch() resetPartialState = (propertiesToClear: Array<keyof InventoryStateModel>): ResetPartialState =>
    new ResetPartialState(propertiesToClear);
}
